*{
    margin:0;
    padding:0;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: bold;
}

.logo{
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    z-index: 4;
}

.pulse{
    display: flex;
    position: relative;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background-color: rgb(0, 0, 0);
}

.pulse span {
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    opacity: 0.5;
    animation: pulseAnimate 4s ease-out infinite;
    animation-delay: calc(2s*var(--i));
}

@keyframes pulseAnimate {
    100% {
        opacity: 0;
        transform: scale(1.4);
    }
}